var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "me-resume" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.tabNames.original, name: "original" } },
            [
              _c("div", { staticClass: "me-resume__content" }, [
                _c("div", { staticClass: "me-resume__content--header" }, [
                  _c(
                    "div",
                    {
                      staticClass: "me-resume__content--top left",
                      on: {
                        click: function($event) {
                          return _vm.downloadFile(
                            _vm.resumeData.base.attachmentPath,
                            _vm.resumeData.base.attachmentName
                          )
                        }
                      }
                    },
                    [
                      _vm.resumeData.base.attachmentName
                        ? _c("img", {
                            attrs: {
                              src: _vm.handlerFilterIcon(
                                _vm.resumeData.base.attachmentName
                              ),
                              alt: ""
                            }
                          })
                        : _vm._e(),
                      _c("label", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.resumeData.base.userName))
                        ]),
                        _c("span", { staticClass: "size" }, [
                          _vm._v(_vm._s(_vm.resumeData.base.expectedSalary))
                        ])
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "source" }, [
                    _vm._v(
                      "\n            来源：" +
                        _vm._s(
                          _vm.resumeData.base.resumeSource
                            ? _vm.sources[_vm.resumeData.base.resumeSource]
                            : ""
                        ) +
                        "\n          "
                    )
                  ])
                ]),
                _c("div", { staticClass: "thumb" }, [
                  _vm.resumeData.base.attachmentViewPath
                    ? _c("img", {
                        attrs: {
                          src: _vm.resumeData.base.attachmentViewPath,
                          alt: ""
                        }
                      })
                    : _vm._e()
                ])
              ])
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.tabNames.standard, name: "standard" } },
            [
              _c("div", { staticClass: "me-resume__content" }, [
                _c(
                  "div",
                  { staticClass: "me-resume__content--standardHeader" },
                  [
                    _c("span", { staticClass: "avatar" }, [
                      _vm.resumeData.base.userAvatar
                        ? _c("img", {
                            attrs: {
                              src: _vm.resumeData.base.userAvatar,
                              alt: ""
                            }
                          })
                        : _c("i", { staticClass: "el-icon-user-solid default" })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "me-resume__content--standardHeader--info"
                      },
                      [
                        _vm.resumeData.base.userName
                          ? _c("h2", {
                              domProps: {
                                innerHTML: _vm._s(_vm.resumeData.base.userName)
                              }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "items" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.genders[_vm.resumeData.base.gender]) +
                              "\n              " +
                              _vm._s(
                                _vm.resumeData.base.userAge
                                  ? _vm.resumeData.base.userAge + "岁"
                                  : ""
                              ) +
                              "\n            "
                          )
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.resumeData.base.jobTitle))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.resumeData.base.address))
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.resumeData.base.phone))]),
                        _c("span", [_vm._v(_vm._s(_vm.resumeData.base.email))])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "me-resume__content--standardHeader--tags"
                      },
                      [
                        _vm._l(_vm.tags, function(tag, index) {
                          return [
                            tag
                              ? _c(
                                  "el-tag",
                                  {
                                    key: index,
                                    attrs: {
                                      type: "info",
                                      closable: _vm.canIEdit
                                    },
                                    on: {
                                      close: function($event) {
                                        return _vm.handleClose(tag)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(tag) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }),
                        _vm.inputVisible && _vm.canIEdit
                          ? _c("el-input", {
                              ref: "saveTagInput",
                              staticClass: "input-new-tag",
                              attrs: { size: "small" },
                              on: { blur: _vm.handleInputConfirm },
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.handleInputConfirm.apply(
                                    null,
                                    arguments
                                  )
                                }
                              },
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            })
                          : _vm.canIEdit
                          ? _c(
                              "el-button",
                              {
                                staticClass: "button-new-tag",
                                attrs: { size: "small" },
                                on: { click: _vm.showInput }
                              },
                              [_vm._v("+ 添加")]
                            )
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm.canIEdit
                      ? _c("i", {
                          staticClass: "el-icon-edit btn",
                          on: {
                            click: function($event) {
                              _vm.showDaseDialog = !_vm.showDaseDialog
                            }
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "me-resume__content--intention" },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v("求职意向\n            "),
                      _vm.canIEdit
                        ? _c("i", {
                            staticClass: "el-icon-edit btn",
                            on: {
                              click: function($event) {
                                _vm.editIntention = !_vm.editIntention
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-width": "90px",
                          model: _vm.resumeData.base
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "职位性质：" } },
                          [
                            _vm.editIntention
                              ? _c("el-input", {
                                  model: {
                                    value: _vm.resumeData.base.jobType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "jobType",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.jobType"
                                  }
                                })
                              : [_vm._v(_vm._s(_vm.resumeData.base.jobType))]
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "期望薪资：" } },
                          [
                            _vm.editIntention
                              ? _c("el-input", {
                                  model: {
                                    value: _vm.resumeData.base.expectedSalary,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "expectedSalary",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.expectedSalary"
                                  }
                                })
                              : [
                                  _vm._v(
                                    _vm._s(_vm.resumeData.base.expectedSalary)
                                  )
                                ]
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "到岗期限：" } },
                          [
                            _vm.editIntention
                              ? _c("el-input", {
                                  model: {
                                    value: _vm.resumeData.base.arrivalTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "arrivalTime",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.arrivalTime"
                                  }
                                })
                              : [
                                  _vm._v(
                                    _vm._s(_vm.resumeData.base.arrivalTime)
                                  )
                                ]
                          ],
                          2
                        ),
                        _vm.editIntention
                          ? _c(
                              "el-form-item",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.editIntention = false
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlerEditBase([
                                          "jobType",
                                          "expectedSalary",
                                          "arrivalTime"
                                        ])
                                      }
                                    }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "me-resume__content--intention" },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v("教育经历 ")
                    ]),
                    _vm._l(_vm.resumeData.educations, function(item, index) {
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "item" },
                          [
                            _c(
                              "p",
                              { staticClass: "item--title" },
                              [
                                _c("span", [
                                  _vm._v(" 教育经历" + _vm._s(index + 1))
                                ]),
                                _vm.canIEdit
                                  ? [
                                      _c("i", {
                                        staticClass: "el-icon-edit btn",
                                        on: {
                                          click: function($event) {
                                            item.editIntention = !item.editIntention
                                          }
                                        }
                                      }),
                                      _c("i", {
                                        staticClass: "el-icon-delete btn",
                                        on: {
                                          click: function($event) {
                                            return _vm.handlerRemove(
                                              item,
                                              index,
                                              "education"
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "el-form",
                              {
                                ref: "educations-" + index,
                                refInFor: true,
                                attrs: {
                                  "label-width": "100px",
                                  model: _vm.resumeData.educations[index]
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开始时间：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "startTime"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                            "value-format": "yyyy-MM-dd"
                                          },
                                          model: {
                                            value: item.startTime,
                                            callback: function($$v) {
                                              _vm.$set(item, "startTime", $$v)
                                            },
                                            expression: "item.startTime"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.startTime))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "结束时间：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "endTime"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                            "value-format": "yyyy-MM-dd"
                                          },
                                          model: {
                                            value: item.endTime,
                                            callback: function($$v) {
                                              _vm.$set(item, "endTime", $$v)
                                            },
                                            expression: "item.endTime"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.endTime))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "院校名称：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "schoolName"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-input", {
                                          model: {
                                            value: item.schoolName,
                                            callback: function($$v) {
                                              _vm.$set(item, "schoolName", $$v)
                                            },
                                            expression: "item.schoolName"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.schoolName))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "学历：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "education"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-input", {
                                          model: {
                                            value: item.education,
                                            callback: function($$v) {
                                              _vm.$set(item, "education", $$v)
                                            },
                                            expression: "item.education"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.education))]
                                  ],
                                  2
                                ),
                                item.editIntention
                                  ? _c(
                                      "el-form-item",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: () => {
                                                item.editIntention = false
                                                _vm.resetForm(
                                                  "educations",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.handlerSubmitForm(
                                                  "educations",
                                                  item.isAdd ? "add" : "edit",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("确定")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }),
                    _vm.canIEdit
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addBtn",
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.resumeData.educations.push({
                                  startTime: "",
                                  endTime: "",
                                  schoolName: "",
                                  education: "",
                                  editIntention: true,
                                  isAdd: true
                                })
                              }
                            }
                          },
                          [_vm._v("+ 添加")]
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "me-resume__content--intention" },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v("工作经历 ")
                    ]),
                    _vm._l(_vm.resumeData.jobs, function(item, index) {
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "item" },
                          [
                            _c(
                              "p",
                              { staticClass: "item--title" },
                              [
                                _c("span", [
                                  _vm._v(" 工作经历" + _vm._s(index + 1))
                                ]),
                                _vm.canIEdit
                                  ? [
                                      _c("i", {
                                        staticClass: "el-icon-edit btn",
                                        on: {
                                          click: function($event) {
                                            item.editIntention = !item.editIntention
                                          }
                                        }
                                      }),
                                      _c("i", {
                                        staticClass: "el-icon-delete btn",
                                        on: {
                                          click: function($event) {
                                            return _vm.handlerRemove(
                                              item,
                                              index,
                                              "job"
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "el-form",
                              {
                                ref: "jobs-" + index,
                                refInFor: true,
                                attrs: {
                                  "label-width": "100px",
                                  model: _vm.resumeData.jobs[index]
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开始时间：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "startTime"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                            "value-format": "yyyy-MM-dd"
                                          },
                                          model: {
                                            value: item.startTime,
                                            callback: function($$v) {
                                              _vm.$set(item, "startTime", $$v)
                                            },
                                            expression: "item.startTime"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.startTime))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "结束时间：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "endTime"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-date-picker", {
                                          attrs: {
                                            type: "date",
                                            placeholder: "选择日期",
                                            "value-format": "yyyy-MM-dd"
                                          },
                                          model: {
                                            value: item.endTime,
                                            callback: function($$v) {
                                              _vm.$set(item, "endTime", $$v)
                                            },
                                            expression: "item.endTime"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.endTime))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "公司名称：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "companyName"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-input", {
                                          model: {
                                            value: item.companyName,
                                            callback: function($$v) {
                                              _vm.$set(item, "companyName", $$v)
                                            },
                                            expression: "item.companyName"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.companyName))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "任职职位：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "jobTitle"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-input", {
                                          model: {
                                            value: item.jobTitle,
                                            callback: function($$v) {
                                              _vm.$set(item, "jobTitle", $$v)
                                            },
                                            expression: "item.jobTitle"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.jobTitle))]
                                  ],
                                  2
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      label: "工作内容：",
                                      rules: {
                                        required: true,
                                        message: " ",
                                        trigger: "blur"
                                      },
                                      prop: "content"
                                    }
                                  },
                                  [
                                    item.editIntention
                                      ? _c("el-input", {
                                          model: {
                                            value: item.content,
                                            callback: function($$v) {
                                              _vm.$set(item, "content", $$v)
                                            },
                                            expression: "item.content"
                                          }
                                        })
                                      : [_vm._v(_vm._s(item.content))]
                                  ],
                                  2
                                ),
                                item.editIntention
                                  ? _c(
                                      "el-form-item",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: () => {
                                                item.editIntention = false
                                                _vm.resetForm("jobs", index)
                                              }
                                            }
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.handlerSubmitForm(
                                                  "jobs",
                                                  item.isAdd ? "add" : "edit",
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("确定")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }),
                    _vm.canIEdit
                      ? _c(
                          "el-button",
                          {
                            staticClass: "addBtn",
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.resumeData.jobs.push({
                                  startTime: "",
                                  endTime: "",
                                  companyName: "",
                                  jobTitle: "",
                                  content: "",
                                  editIntention: true,
                                  isAdd: true
                                })
                              }
                            }
                          },
                          [_vm._v("+ 添加")]
                        )
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "me-resume__content--intention" },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v("自我评价\n            "),
                      _vm.canIEdit
                        ? _c("i", {
                            staticClass: "el-icon-edit btn",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.resumeData.editSelfAssessment = !_vm
                                  .resumeData.editSelfAssessment
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "el-form",
                      {
                        attrs: { "label-width": "20px" },
                        model: {
                          value: _vm.assessment,
                          callback: function($$v) {
                            _vm.assessment = $$v
                          },
                          expression: "assessment"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { staticStyle: { width: "100%" } },
                          [
                            _vm.resumeData.editSelfAssessment
                              ? _c("el-input", {
                                  attrs: { type: "textarea" },
                                  model: {
                                    value: _vm.resumeData.base.selfAssessment,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "selfAssessment",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.selfAssessment"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.resumeData.base.selfAssessment)
                                  )
                                ])
                          ],
                          1
                        ),
                        _vm.resumeData.editSelfAssessment
                          ? _c(
                              "el-form-item",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.resumeData.editSelfAssessment = !_vm
                                          .resumeData.editSelfAssessment
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlerEditBase([
                                          "selfAssessment"
                                        ])
                                      }
                                    }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "me-resume__content--intention" },
                  [
                    _c("label", { staticClass: "title" }, [
                      _vm._v("其他\n            "),
                      _vm.canIEdit
                        ? _c("i", {
                            staticClass: "el-icon-edit btn",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.resumeData.editOther = !_vm.resumeData
                                  .editOther
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "el-form",
                      { attrs: { model: _vm.other, "label-width": "90px" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { label: "技能：" }
                          },
                          [
                            _vm.resumeData.editOther
                              ? _c("el-input", {
                                  attrs: { type: "textarea" },
                                  model: {
                                    value: _vm.resumeData.base.skillTags,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "skillTags",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.skillTags"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.resumeData.base.skillTags) + " "
                                  )
                                ])
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { label: "兴趣爱好：" }
                          },
                          [
                            _vm.resumeData.editOther
                              ? _c("el-input", {
                                  attrs: { type: "textarea" },
                                  model: {
                                    value: _vm.resumeData.base.hobbies,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.resumeData.base,
                                        "hobbies",
                                        $$v
                                      )
                                    },
                                    expression: "resumeData.base.hobbies"
                                  }
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.resumeData.base.hobbies)
                                  )
                                ])
                          ],
                          1
                        ),
                        _vm.resumeData.editOther
                          ? _c(
                              "el-form-item",
                              { staticStyle: { width: "100%" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function($event) {
                                        _vm.resumeData.editOther = !_vm
                                          .resumeData.editOther
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handlerEditBase([
                                          "skillTags",
                                          "hobbies"
                                        ])
                                      }
                                    }
                                  },
                                  [_vm._v("确定")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: _vm.tabNames.record, name: "record" } },
            [
              _vm.resumeData.base.id
                ? _c("Comments", {
                    attrs: { id: _vm.resumeData.base.id, type: 6 }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.showDaseDialog, width: "500px" },
          on: {
            "update:visible": function($event) {
              _vm.showDaseDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.resumeData.base.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "userName", $$v)
                      },
                      expression: "resumeData.base.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "年龄" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.resumeData.base.userAge,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "userAge", $$v)
                      },
                      expression: "resumeData.base.userAge"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最高学历" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "degrees_selected",
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    _vm._l(Object.keys(_vm.degrees), function(item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item, label: _vm.degrees[item] }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "求职意向" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.resumeData.base.jobTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "jobTitle", $$v)
                      },
                      expression: "resumeData.base.jobTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "现居地" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.resumeData.base.address,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "address", $$v)
                      },
                      expression: "resumeData.base.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.resumeData.base.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "phone", $$v)
                      },
                      expression: "resumeData.base.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.resumeData.base.email,
                      callback: function($$v) {
                        _vm.$set(_vm.resumeData.base, "email", $$v)
                      },
                      expression: "resumeData.base.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDaseDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handlerEditBase([
                        "userName",
                        "userAge",
                        "highestDegree",
                        "jobTitle",
                        "address",
                        "phone",
                        "email"
                      ])
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }