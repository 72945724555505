<template>
  <div class="me-resume">
    <el-tabs v-model="activeName">
      <el-tab-pane :label="tabNames.original" name="original">
        <div class="me-resume__content">
          <div class="me-resume__content--header">
            <div
              class="me-resume__content--top left"
              @click="
                downloadFile(
                  resumeData.base.attachmentPath,
                  resumeData.base.attachmentName
                )
              "
            >
              <img
                v-if="resumeData.base.attachmentName"
                :src="handlerFilterIcon(resumeData.base.attachmentName)"
                alt=""
              />
              <label>
                <span>{{ resumeData.base.userName }}</span>
                <span class="size">{{ resumeData.base.expectedSalary }}</span>
              </label>
            </div>
            <div class="source">
              来源：{{
                resumeData.base.resumeSource
                  ? sources[resumeData.base.resumeSource]
                  : ""
              }}
            </div>
          </div>
          <div class="thumb">
            <img
              v-if="resumeData.base.attachmentViewPath"
              :src="resumeData.base.attachmentViewPath"
              alt=""
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="tabNames.standard" name="standard">
        <div class="me-resume__content">
          <div class="me-resume__content--standardHeader">
            <span class="avatar">
              <img
                v-if="resumeData.base.userAvatar"
                :src="resumeData.base.userAvatar"
                alt=""
              />
              <i v-else class="el-icon-user-solid default"></i>
            </span>
            <div class="me-resume__content--standardHeader--info">
              <h2
                v-if="resumeData.base.userName"
                v-html="resumeData.base.userName"
              ></h2>
              <div class="items">
                {{ genders[resumeData.base.gender] }}
                {{
                  resumeData.base.userAge ? resumeData.base.userAge + "岁" : ""
                }}
              </div>
              <span>{{ resumeData.base.jobTitle }}</span>
              <span>{{ resumeData.base.address }}</span>
              <span>{{ resumeData.base.phone }}</span>
              <span>{{ resumeData.base.email }}</span>
            </div>
            <div class="me-resume__content--standardHeader--tags">
              <template v-for="(tag, index) in tags">
                <el-tag
                  :key="index"
                  v-if="tag"
                  type="info"
                  :closable="canIEdit"
                  @close="handleClose(tag)"
                >
                  {{ tag }}
                </el-tag>
              </template>
              <el-input
                class="input-new-tag"
                v-if="inputVisible && canIEdit"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              >
              </el-input>
              <el-button
                v-else-if="canIEdit"
                class="button-new-tag"
                size="small"
                @click="showInput"
                >+ 添加</el-button
              >
            </div>
            <i
              v-if="canIEdit"
              @click="showDaseDialog = !showDaseDialog"
              class="el-icon-edit btn"
            ></i>
          </div>
          <div class="me-resume__content--intention">
            <label class="title"
              >求职意向
              <i
                v-if="canIEdit"
                @click="editIntention = !editIntention"
                class="el-icon-edit btn"
              ></i>
            </label>
            <el-form label-width="90px" :model="resumeData.base">
              <el-form-item label="职位性质：">
                <el-input
                  v-model="resumeData.base.jobType"
                  v-if="editIntention"
                ></el-input>
                <template v-else>{{ resumeData.base.jobType }}</template>
              </el-form-item>
              <el-form-item label="期望薪资：">
                <el-input
                  v-model="resumeData.base.expectedSalary"
                  v-if="editIntention"
                ></el-input>
                <template v-else>{{ resumeData.base.expectedSalary }}</template>
              </el-form-item>
              <el-form-item label="到岗期限：">
                <el-input
                  v-model="resumeData.base.arrivalTime"
                  v-if="editIntention"
                ></el-input>
                <template v-else>{{ resumeData.base.arrivalTime }}</template>
              </el-form-item>
              <el-form-item v-if="editIntention" style="width:100%">
                <el-button @click="editIntention = false">取消</el-button>
                <el-button
                  type="primary"
                  @click="
                    handlerEditBase([
                      'jobType',
                      'expectedSalary',
                      'arrivalTime'
                    ])
                  "
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <!-- 教育经历 -->
          <div class="me-resume__content--intention">
            <label class="title">教育经历 </label>
            <template v-for="(item, index) in resumeData.educations">
              <div class="item" :key="index">
                <p class="item--title">
                  <span> 教育经历{{ index + 1 }}</span>
                  <template v-if="canIEdit">
                    <i
                      @click="item.editIntention = !item.editIntention"
                      class="el-icon-edit btn"
                    ></i>
                    <i
                      class="el-icon-delete btn"
                      @click="handlerRemove(item, index, 'education')"
                    ></i>
                  </template>
                </p>
                <el-form
                  label-width="100px"
                  :model="resumeData.educations[index]"
                  :ref="'educations-' + index"
                >
                  <el-form-item
                    label="开始时间："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="startTime"
                  >
                    <el-date-picker
                      type="date"
                      v-model="item.startTime"
                      v-if="item.editIntention"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <template v-else>{{ item.startTime }}</template>
                  </el-form-item>
                  <el-form-item
                    label="结束时间："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="endTime"
                  >
                    <el-date-picker
                      type="date"
                      v-model="item.endTime"
                      v-if="item.editIntention"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <template v-else>{{ item.endTime }}</template>
                  </el-form-item>
                  <el-form-item
                    label="院校名称："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="schoolName"
                  >
                    <el-input
                      v-model="item.schoolName"
                      v-if="item.editIntention"
                    ></el-input>
                    <template v-else>{{ item.schoolName }}</template>
                  </el-form-item>
                  <el-form-item
                    label="学历："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="education"
                  >
                    <el-input
                      v-model="item.education"
                      v-if="item.editIntention"
                    ></el-input>
                    <template v-else>{{ item.education }}</template>
                  </el-form-item>
                  <el-form-item v-if="item.editIntention" style="width:100%">
                    <el-button
                      @click="
                        () => {
                          item.editIntention = false;
                          resetForm('educations', index);
                        }
                      "
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        handlerSubmitForm(
                          'educations',
                          item.isAdd ? 'add' : 'edit',
                          index
                        )
                      "
                      >确定</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </template>
            <el-button
              v-if="canIEdit"
              class="addBtn"
              size="small"
              @click="
                resumeData.educations.push({
                  startTime: '',
                  endTime: '',
                  schoolName: '',
                  education: '',
                  editIntention: true,
                  isAdd: true
                })
              "
              >+ 添加</el-button
            >
          </div>
          <!-- 工作经历 -->
          <div class="me-resume__content--intention">
            <label class="title">工作经历 </label>
            <template v-for="(item, index) in resumeData.jobs">
              <div class="item" :key="index">
                <p class="item--title">
                  <span> 工作经历{{ index + 1 }}</span>
                  <template v-if="canIEdit">
                    <i
                      @click="item.editIntention = !item.editIntention"
                      class="el-icon-edit btn"
                    ></i>
                    <i
                      class="el-icon-delete btn"
                      @click="handlerRemove(item, index, 'job')"
                    ></i>
                  </template>
                </p>
                <el-form
                  label-width="100px"
                  :model="resumeData.jobs[index]"
                  :ref="'jobs-' + index"
                >
                  <el-form-item
                    label="开始时间："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="startTime"
                  >
                    <el-date-picker
                      type="date"
                      v-model="item.startTime"
                      v-if="item.editIntention"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <template v-else>{{ item.startTime }}</template>
                  </el-form-item>
                  <el-form-item
                    label="结束时间："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="endTime"
                  >
                    <el-date-picker
                      type="date"
                      v-model="item.endTime"
                      v-if="item.editIntention"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <template v-else>{{ item.endTime }}</template>
                  </el-form-item>
                  <el-form-item
                    label="公司名称："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="companyName"
                  >
                    <el-input
                      v-model="item.companyName"
                      v-if="item.editIntention"
                    ></el-input>
                    <template v-else>{{ item.companyName }}</template>
                  </el-form-item>
                  <el-form-item
                    label="任职职位："
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="jobTitle"
                  >
                    <el-input
                      v-model="item.jobTitle"
                      v-if="item.editIntention"
                    ></el-input>
                    <template v-else>{{ item.jobTitle }}</template>
                  </el-form-item>
                  <el-form-item
                    label="工作内容："
                    style="width:100%"
                    :rules="{
                      required: true,
                      message: ' ',
                      trigger: 'blur'
                    }"
                    prop="content"
                  >
                    <el-input
                      v-model="item.content"
                      v-if="item.editIntention"
                    ></el-input>
                    <template v-else>{{ item.content }}</template>
                  </el-form-item>
                  <el-form-item v-if="item.editIntention" style="width:100%">
                    <el-button
                      @click="
                        () => {
                          item.editIntention = false;
                          resetForm('jobs', index);
                        }
                      "
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        handlerSubmitForm(
                          'jobs',
                          item.isAdd ? 'add' : 'edit',
                          index
                        )
                      "
                      >确定</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </template>
            <el-button
              v-if="canIEdit"
              class="addBtn"
              size="small"
              @click="
                resumeData.jobs.push({
                  startTime: '',
                  endTime: '',
                  companyName: '',
                  jobTitle: '',
                  content: '',
                  editIntention: true,
                  isAdd: true
                })
              "
              >+ 添加</el-button
            >
          </div>
          <div class="me-resume__content--intention">
            <label class="title"
              >自我评价
              <i
                v-if="canIEdit"
                @click.prevent="
                  resumeData.editSelfAssessment = !resumeData.editSelfAssessment
                "
                class="el-icon-edit btn"
              ></i
            ></label>
            <el-form v-model="assessment" label-width="20px">
              <el-form-item style="width:100%">
                <el-input
                  type="textarea"
                  v-if="resumeData.editSelfAssessment"
                  v-model="resumeData.base.selfAssessment"
                ></el-input>
                <span v-else> {{ resumeData.base.selfAssessment }}</span>
              </el-form-item>
              <el-form-item
                style="width:100%"
                v-if="resumeData.editSelfAssessment"
              >
                <el-button
                  @click="
                    resumeData.editSelfAssessment = !resumeData.editSelfAssessment
                  "
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  @click="handlerEditBase(['selfAssessment'])"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="me-resume__content--intention">
            <label class="title"
              >其他
              <i
                v-if="canIEdit"
                @click.prevent="resumeData.editOther = !resumeData.editOther"
                class="el-icon-edit btn"
              ></i
            ></label>
            <el-form :model="other" label-width="90px">
              <el-form-item style="width:100%" label="技能：">
                <el-input
                  type="textarea"
                  v-if="resumeData.editOther"
                  v-model="resumeData.base.skillTags"
                ></el-input>
                <span v-else>{{ resumeData.base.skillTags }} </span>
              </el-form-item>
              <el-form-item style="width:100%" label="兴趣爱好：">
                <el-input
                  type="textarea"
                  v-if="resumeData.editOther"
                  v-model="resumeData.base.hobbies"
                ></el-input>
                <span v-else> {{ resumeData.base.hobbies }}</span>
              </el-form-item>
              <el-form-item style="width:100%" v-if="resumeData.editOther">
                <el-button @click="resumeData.editOther = !resumeData.editOther"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  @click="handlerEditBase(['skillTags', 'hobbies'])"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="tabNames.record" name="record">
        <Comments
          v-if="resumeData.base.id"
          :id="resumeData.base.id"
          :type="6"
        ></Comments>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="showDaseDialog" width="500px">
      <el-form label-width="100px">
        <el-form-item label="姓名">
          <el-input
            v-model="resumeData.base.userName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="resumeData.base.userAge"></el-input>
        </el-form-item>
        <el-form-item label="最高学历">
          <el-select v-model="selected" class="degrees_selected">
            <el-option
              v-for="(item, index) in Object.keys(degrees)"
              :key="index"
              :value="item"
              :label="degrees[item]"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="求职意向">
          <el-input v-model="resumeData.base.jobTitle"></el-input>
        </el-form-item>
        <el-form-item label="现居地">
          <el-input v-model="resumeData.base.address"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="resumeData.base.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="resumeData.base.email"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDaseDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            handlerEditBase([
              'userName',
              'userAge',
              'highestDegree',
              'jobTitle',
              'address',
              'phone',
              'email'
            ])
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { toBytesSize, downloadFile } from "@/utils";
import Comments from "@/components/business/web-site/comments";
import {
  resumeInfo,
  addEducation,
  editEducation,
  removeEducation,
  modifyBase
} from "@/api/rest";
export default {
  name: "Resume",
  data() {
    return {
      activeName: "original",
      canIEdit: true,
      inputVisible: false,
      inputValue: "",
      editIntention: false,
      showDaseDialog: false,
      assessment: {},
      other: {},
      tags: [],
      tabNames: {
        original: "原始简历",
        standard: "标准简历",
        record: "招聘记录"
      },
      degrees: {},
      genders: {},
      sources: {},
      selected: "",
      resumeData: {
        base: {},
        jobs: [],
        educations: []
      }
    };
  },
  components: {
    Comments
  },
  watch: {
    activeName: {
      handler(newly) {
        // if (newly === "record") this.handlerGetReplays();
      },
      deep: true
    },
    "$route.params.rid": {
      handler() {
        this.handlerGetResumeInfo();
      },
      deep: true
    },
    tags: {
      handler() {
        this.handlerEditBase(["assessmentTags"]);
      },
      deep: true
    }
  },
  async created() {
    await this.handlerGetResumeInfo();

    this.tags = this.resumeData.base.assessmentTags.split(",");
    const resumeStore = this.$store.state.resumes;
    this.degrees = resumeStore.degrees;
    this.sources = resumeStore.sources;
    this.genders = resumeStore.genders;
    if (this.resumeData.base.highestDegree !== undefined)
      this.selected = this.degrees[this.resumeData.base.highestDegree];
  },
  methods: {
    toBytesSize,
    downloadFile,

    async handlerEditBase(keys) {
      if (!keys || !keys.length) return;
      const params = {};
      keys.map(key => {
        if (key == "assessmentTags") params[key] = this.tags.join(",");
        else if (key === "highestDegree") params[key] = this.selected;
        else params[key] = this.resumeData.base[key];
      });
      const result = await modifyBase(this.resumeData.base.id, params);
      if (result && result.code === 200) {
        this.editIntention = this.resumeData.editSelfAssessment = this.resumeData.editOther = this.showDaseDialog = false;
      }
      // console.log(result, "/modify");
    },
    resetForm(formName, index) {
      const fromVm = this.$refs[formName + "-" + index];
      fromVm[0].resetFields();
    },
    handlerRemove(data, index, type) {
      // console.log(data, "/data");
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (data.id) removeEducation(data.id, type);
          this.resumeData[type + "s"].splice(index, 1);
        })
        .catch(() => {});
    },
    handlerSubmitForm(formName, type, index) {
      const fromVm = this.$refs[formName + "-" + index];

      fromVm[0].validate(async valid => {
        if (valid) {
          const data = this.resumeData[formName][index];
          const postData = {
            resumeId: this.resumeData.base.id,
            ...data
          };
          delete postData.editIntention;
          delete postData.isAdd;
          const methodType = formName === "jobs" ? "job" : "education";
          if (type === "add") {
            const result = await addEducation(postData, methodType);
            if (result && result.code === 200) {
              this.resumeData[formName][index].editIntention = false;
              delete this.resumeData[formName][index].isAdd;
              this.handlerGetResumeInfo();
            }
          } else {
            const result = await editEducation(postData, methodType);
            if (result && result.code === 200) {
              this.resumeData[formName][index].editIntention = false;
              delete this.resumeData[formName][index].isAdd;
              this.handlerGetResumeInfo();
            }
            // console.log("edit ", postData, data, index, result);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlerFilterIcon(name) {
      if (!name) return "";
      const suffix = name.substr(name.lastIndexOf(".") + 1, 3);

      return this.formartfileIcon(suffix);
    },
    async handlerGetResumeInfo() {
      const result = await resumeInfo(this.$route.params.rid);
      if (result && result.code === 200) {
        this.resumeData = {
          ...result.data.data,
          editOther: false,
          editSelfAssessment: false
        };
      }
      if (this.resumeData.educations)
        this.resumeData.educations = this.resumeData.educations.map(el => {
          return { ...el, editIntention: false };
        });
      if (this.resumeData.jobs)
        this.resumeData.jobs = this.resumeData.jobs.map(el => {
          return { ...el, editIntention: false };
        });
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/mixins.styl';
.el-select-dropdown{
  z-index 3000 !important
}
.el-select{
  width 100%
}
.addBtn{
  width 90px
  margin-top .9rem
}
.el-input__icon{
  line-height 30px
}
.button-new-tag {
    margin-left: 5px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius 3rem
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    border-radius 3rem
    vertical-align: bottom;
  }
    +b(me-resume){
        padding .5rem 0;
        width 60%;
        margin 2rem auto;
        min-height 78vh
        border 1px solid #e5e5e5
        .el-tabs{
            +e(header){
                margin 0;
            }
            +e(nav-wrap){
                padding .25rem .9rem 0
            }
        }
        +e(content){
            display flex;
            flex-flow column
            .thumb{
                display flex
                justify-content center
                align-items center

                img{
                    object-fit cover
                    max-width 100%
                    margin .8rem 0
                }
            }
            +m(intention){
              display flex;
              flex-flow column
              padding .9rem
              .title{
                border-left 4px solid #33cc66
                padding-left .9rem
              }
              .item{
                margin 1.5rem 0 0
                +m(title){
                  font-size 15px;
                  padding-left .5rem;
                  span{
                    margin-right .5rem
                  }
                  i{
                    cursor pointer
                    margin 0 0 0 .5rem
                    &.el-icon-delete{
                      color red
                    }
                  }
                }
              }
              .el-form{
                display flex
                flex-flow row
                flex-wrap wrap
                padding .5rem 0
                margin 0;
                .el-form-item{
                  width 50%
                  margin-bottom 6px
                  +e(label){
                    line-height 30px
                  }
                  +e(content){
                    line-height 30px
                    .el-textarea{
                      margin-bottom .5rem
                    }
                    .el-button{
                      padding 8px 10px
                    }
                    .el-input{
                      width 100%
                      height 30px
                     input{
                        height 30px;
                        line-height 30px
                     }
                    }
                  }
                }
              }
            }
            +m(standardHeader){
                display flex;
                flex-flow row
                background rgb(250,250,250)
                padding .9rem 0
                justify-content space-evenly
                .avatar{
                    // padding 2rem
                    // font-size 35px
                    margin 1rem 3rem
                    color #909090
                    display inline-block
                    border-radius 5rem
                    img{
                        object-fit cover
                        width 80px
                        height 80px
                        border-radius 5rem
                    }
                    i{
                        font-size 62px;
                        padding .5rem
                        border 1px solid #e5e5e5
                        border-radius 5rem
                    }
                }
                +m(info){
                    display flex
                    flex-flow column
                    font-size 14px;
                    color #666666
                    line-height 26px
                    margin-right 1.5rem
                    h2{
                      color #333333
                    }
                    span{
                        flex 1
                    }
                }
                +m(tags){
                  margin 2.5rem 0
                  flex 1
                  span{
                    border-radius 3rem
                    // height 22px;
                    margin .25rem
                  }
                }
                .btn{
                  // flex 1
                  min-width 30px
                  margin .9rem .5rem
                  color #666666
                  cursor pointer
                  &.el-icon-delete{
                    margin 0 .5rem
                    color red
                  }
                }
            }
            +m(header){
                display flex
                flex 1
                flex-flow row;
                justify-content space-between
                background rgba(228,236,250,1)
                padding .5rem .9rem;
                align-items center
                .left{
                    display flex;
                    flex-flow row;
                    align-items center
                    justify-content flex-start
                    flex 1;
                    cursor pointer
                    img{
                        width 60px;
                        height 60px;
                        object-fit cover
                        margin-right .5rem;
                    }
                    label{
                        display flex
                        flex-flow column
                        .size{
                            font-size 12px;
                            color #949494
                        }
                    }
                }
                .source{
                    font-size 14px;
                }
            }
        }
    }
</style>
